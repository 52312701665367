/* CustomCursor.css */

body {
    cursor: none;
}

a, p, span {
    cursor: none;
}

/* Hide cursor over canvas element */
canvas {
    cursor: none;
}

.custom-cursor {
    position: fixed;
    pointer-events: none;
    z-index: 9999;
    /*top: 0;*/
    /*left: 0;*/
    /*width: 100%;*/
    /*height: 100%;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-cursor img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px; /* Adjust the width and height as per your cursor image */
    height: 40px;
}


