.container {
  display: flex;
  background-color: #FBF4E4;
  /*background-color: #6e4646;*/
  height: 100%; /* Adjust the height as needed */
  justify-content: center; /* Center horizontally */
  /*align-items: center; !* Center vertically *!*/
}

.left {
  flex: 1;
  /*padding: 30px; !* Increased padding *!*/
  /*margin: 100px; !* Increased padding *!*/
  /*max-width: 500px; !* Max width set to 500px *!*/
  /*overflow-y: auto; !* Add scrollbar if content overflows vertically *!*/
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  max-width: 100%; /* Set max width to 100% */
  max-height: 100%; /* Set max height to 100% */

}

.left p{
  width: 400px;
  margin: 0 auto;
  margin-top: 100px;
}

.token {
  max-width: 55%;
  position: absolute;
  top: 21vh;
  object-fit: cover;
}

.right {
  flex: 1;
  display: flex;
  align-items: center;
  /*padding: 20px;*/
  /*margin: 30px; !* Increased padding *!*/
  justify-content: center; /* Center horizontally */
  position: relative; /* Set position to relative */
}

.cat {
  max-width: 100%; /* Set max width to 80px */
  height: auto;
  position: absolute; /* Set position to absolute */
  top: 50vh; /* Place the top of the image at the vertical center */
  left: 50%; /* Place the left edge of the image at the horizontal center */
  transform: translate(-50%, -50%); /* Translate the image back by half its width and height to center it */
  max-width: 100%; /* Set max width to 100% */
  max-height: 100%; /* Set max height to 100% */
  z-index: -1;
}

.twi {
  position: absolute; /* Set position to absolute */
  width: 44px;
  height: 44px;
  top: 78vh; /* Place the top of the image at the vertical center */
  left: 30%; /* Place the left edge of the image at the horizontal center */
  transform: translate(-214%, -11%); /* Translate the image back by half its width and height to center it */
  max-width: 100%; /* Set max width to 100% */
  max-height: 100%; /* Set max height to 100% */
}

.far {
  position: absolute; /* Set position to absolute */
  width: 44px;
  height: 44px;
  top: 78vh; /* Place the top of the image at the vertical center */
  left: 30%; /* Place the left edge of the image at the horizontal center */
  transform: translate(-104%, -11%); /* Translate the image back by half its width and height to center it */
  max-width: 100%; /* Set max width to 100% */
  max-height: 100%; /* Set max height to 100% */
  margin-left: 4px;
  border-radius: 10px;
}

.volume {
  position: absolute; /* Set position to absolute */
  width: 80px;
  height: 80px;
  top: 78vh; /* Place the top of the image at the vertical center */
  left: 30%; /* Place the left edge of the image at the horizontal center */
  transform: translate(12%, -27%); /* Translate the image back by half its width and height to center it */
  max-width: 100%; /* Set max width to 100% */
  max-height: 100%; /* Set max height to 100% */
}

.container {
  position: relative;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the color and transparency of the ripple effect */
  pointer-events: none;
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
}

.scratch-card {
  position: absolute;
  top: 0;
  left: 0;
  margin: 100px 0px 70px 210px;
}

