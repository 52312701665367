/* Reset box model */
* {
    box-sizing: border-box;
}

/* Remove default margin and padding */
body, h1, h2, h3, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
    margin: 0;
    padding: 0;
}

/* Remove list styles */
ul, ol {
    list-style: none;
}

/* Remove hyperlink text decoration */
a {
    text-decoration: none;
}

/* Remove outline on focused elements */
:focus {
    outline: none;
}

/*body {*/
/*    cursor: url('/src/cursor.png'), auto;*/
/*    !* 위 경로를 커서 이미지 파일의 실제 경로로 변경하세요 *!*/
/*}*/

body {
    cursor: none;
}

a, p, span {
    cursor: none;
}

/* Hide cursor over canvas element */
canvas {
    cursor: none;
}

img:hover {
    cursor: none;
}